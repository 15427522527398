/* Variables */
:root {
  --logo-padding: 16px;
  --logo-padding-sm: 3px;
  --bp-label-grey: #868e96;
}

/* Align those pesky SVG with text. 
  https://blog.prototypr.io/align-svg-icons-to-text-and-say-goodbye-to-font-icons-d44b3d7b26b4
*/
.svg-icon-svg-baseline svg {
  top: 0.125em;
  position: relative;
}

#freshworks-frame-wrapper,
#launcher-frame {
  right: unset !important;
  left: 30px !important;
}
.frame-right {
  animation: slideupleft 0.3s ease !important;
}

#__next {
  min-height: 100vh;
}

.tabler-icon {
  /* width: 18px;
  height: 18px; */
  stroke-width: 1.5;
  vertical-align: text-top;
}

/* Hide Freshdesk help button */
#launcher-frame {
  display: none;
}
