.comboboxOption {
  &[data-combobox-selected] {
    background-color: var(--mantine-color-blue-2);
    color: inherit;
    font-weight: 500;
  }
}

/* Inline variant for Input Labels, to position the required '*' at the top-left */
.label {
  &[data-variant='inline'] {
    position: relative;
    line-height: 34px;

    .required {
      position: absolute;
      top: 0;
      left: -10px;
    }
  }
}
